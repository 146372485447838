import { NetworkChainId } from "./blockchain.model";
export type WalletAddress = `0x${Lowercase<string>}`;

enum TokenAddressEnum {
  USDC = "USDC",
  Ethereum = "Ethereum"
}

const tokenAddresses: Partial<Record<NetworkChainId, Record<TokenAddressEnum, WalletAddress>>> = {
  [NetworkChainId.Ethereum]: {
    USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    Ethereum: "0x0000000000000000000000000000000000000000" as WalletAddress
  },
  [NetworkChainId.Sepolia]: {
    USDC: "0xf667d515097d312fefeb7f7afdba15d57aae30d4",
    Ethereum: "0x0000000000000000000000000000000000000000"
  }
};

const devPortalWalletAddress: Partial<Record<NetworkChainId, WalletAddress>> = {
  [NetworkChainId.Ethereum]: "0x1e43c5f5244eaee3e170bad145bf80b841196564",
  [NetworkChainId.Sepolia]: "0xa0333709c6be47412637084f4dbf8b7e8f271888"
};

export const getTokenAddresses = (network: NetworkChainId = NetworkChainId.Sepolia) => {
  const addresses = tokenAddresses[network];
  if (!addresses) {
    throw new Error("Token addresses not found");
  }
  return addresses;
};

export const getDevPortalWalletAddress = (network: NetworkChainId = NetworkChainId.Sepolia) => {
  const address = devPortalWalletAddress[network];
  if (!address) {
    throw new Error("Dev portal wallet address not found");
  }
  return address;
};

const scanBaseUrls: Partial<Record<NetworkChainId, string>> = {
  [NetworkChainId.Ethereum]: "https://etherscan.io",
  [NetworkChainId.Sepolia]: "https://sepolia.etherscan.io"
};

export const getChainScanBaseUrl = (network: NetworkChainId = NetworkChainId.Sepolia) => {
  const url = scanBaseUrls[network];
  if (!url) {
    throw new Error("Chain scan base url not found");
  }
  return url;
};

const minimumConfirmedBlocks: Partial<Record<NetworkChainId, number>> = {
  [NetworkChainId.Ethereum]: 5,
  [NetworkChainId.Sepolia]: 1
};

export const getMinimumConfirmedBlocks = (network: NetworkChainId = NetworkChainId.Sepolia) => {
  const blocks = minimumConfirmedBlocks[network];
  if (!blocks) {
    throw new Error("Minimum confirmed blocks not found");
  }
  return blocks;
};

export enum NetworkChainId {
  Ethereum = 1,
  Solana = 501,
  Sepolia = 11155111,
  Arbitrum = 42161,
  Avalanche = 43114,
  Bsc = 56,
  Optimism = 10,
  Fantom = 250,
  Gnosis = 100,
  Polygon = 137,
  ZkSync = 324,
  Base = 8453
}

export type ChainId = number;
